import React from 'react';
import SnapSlider from '../components/SnapSlider';
import Header from '../components/Header';
import '../styles/App.css'

const Home = ()=>{
    return <>
        <Header />
        <SnapSlider />
    </>
}

export default Home;